import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';

function Messages(props) {  
    const ta_token = localStorage.getItem("ta_token");
    const [editingId, setEditingId] = useState(null);
    const [data, setData] = useState([]);
    const [charsCounter, setCharsCounter] = useState(0);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const inputRef = useRef(null);
    const [messageData, setMessageData] = useState({
      messageId: '',
      messageText: '',
    });
    const navigate = useNavigate();

    async function loadMessages()
    {
      const response = await fetch(API_ENDPOINT + '/getMessages',
      {
          headers: {
            Authorization: `Bearer ${ta_token}`
          },
          method: 'GET',
      });
      const dataResponse = await response.json();
      if (!dataResponse.status)
        {
          navigate('/');
        } else {
          setData(dataResponse.messages);
        }
    }
    useEffect(() => {
        loadMessages();        
    }, []);    
    const deleteMessageTemplate = async (messageId) => {
      const confirmRemove = window.confirm('נא לאשר את מחיקת הרשומה');
      if (confirmRemove) {
        const formData = new FormData();
        formData.append('messageId', messageId);
        const response = await fetch(API_ENDPOINT + '/deleteMessage',
          {
              headers: {
                Authorization: `Bearer ${ta_token}`
              },
              method: 'POST',
              body: formData
          });
          const newItems = data.filter(item => item.messageId !== messageId);
          setData(newItems);
      }
    }
    const setDefaultMessage = async (messageId) => {
      const formData = new FormData();
      formData.append('messageId', messageId);
      const response = await fetch(API_ENDPOINT + '/setDefaultMessage',
        {
            headers: {
              Authorization: `Bearer ${ta_token}`
            },
            method: 'POST',
            body: formData
        });
      const response_data = await response.json();
      const newData = data.map(item => {
          if (item.messageId === messageId) {
            return { ...item, messageIsDefault: 1 };
          } else {
            return { ...item, messageIsDefault: 0 };
          }
          return item;
      });
      setData(newData);
    }
    const createNewMessageTemplate = async () => {
        const newItem = {
          messageId: '',
          messageIsDefault: '',
          messageText: '',
        };
        const messageId = newItem.messageId;
        const messageIsDefault = newItem.messageIsDefault;
        const messageText = newItem.messageText;
        setData([newItem,...data]);
        setEditingId( messageId );
        setMessageData({ messageId, messageIsDefault, messageText })        
        setTimeout(function(e){
          document.getElementById("messageText").focus();
        },50);        
    }
    const setSaveMessageText = async(messageId) => {
      const formData = new FormData();
      formData.append('messageId', messageId);
      formData.append('messageText', messageData.messageText);
      const response = await fetch(API_ENDPOINT + '/updateMessage',
        {
            headers: {
              Authorization: `Bearer ${ta_token}`
            },
            method: 'POST',
            body: formData
        });
      const newData = data.map(item => {
        if (item.messageId === messageId) {
          return { ...item, messageId: messageData.messageId, messageText: messageData.messageText };
        }
        return item;
      });
      setData(newData);
      setEditingId(null);
    }
    const handleMacroItemClick = (event) => {
      const clickedText = event.target.textContent;      
      let messageText = document.getElementById('messageText').value;
      const cursorPosition = document.getElementById('messageText').selectionStart;
      const newInputValue = messageText.slice(0, cursorPosition) + clickedText + messageText.slice(cursorPosition);
      document.getElementById('messageText').setSelectionRange(cursorPosition + clickedText.length, cursorPosition + clickedText.length);
      inputRef.current.value = newInputValue;
      document.getElementById('messageText').focus();
      //inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      //setCharsCounter(inputRef.current.value.length);
    };  
    const handleInputChange = (event) => {
      setCharsCounter(inputRef.current.value.length)
      setMessageData({ ...messageData, [event.target.name]: event.target.value });
    }
    const setEditMessageText = (messageId,messageText) => {      
      setEditingId( messageId );
      setCharsCounter(messageText.length);
      setMessageData({ messageId, messageText })
    }
    return (
        <div className="app adminpanel">
          <header>
          </header>
          <main>
            <div class="main">
              <div class="marea">
                <div class="contents">
                <div class="contents_head">
                  <h1>ניהול הודעות</h1>
                  <img src="icon_create.svg" class="icon_create" onClick={createNewMessageTemplate} />
                </div>                
                <table cellPadding="0" cellspacing="0" width="100%" border="0">
                  <thead>
                    <tr>
                      <th>תוכן ההודעה</th>
                      <th></th>
                      <th>פעולות</th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.map((row) => (
                    <tr key={row.messageId}>
                      <td class="w-75">                          
                          {editingId === row.messageId ? (
                            <div>
                              <input type="text" class="longInput" name="messageText" id="messageText" value={messageData.messageText} onFocus={handleInputChange} onChange={handleInputChange} ref={inputRef} />
                              &nbsp;&nbsp;&nbsp;<span>{charsCounter}</span>
                            </div>
                          ) : (
                            row.messageText
                          )}                          
                      </td>
                      <td>{row.messageIsDefault?'ברירת מחדל':''}</td>
                      <td>
                        {editingId === row.messageId ? (
                          <i onClick={() => setSaveMessageText(row.messageId)}><img src="icon_save.svg" alt="שמירה" title="שמירה" /></i>
                        ) : (
                          <i class="edit" onClick={() => setEditMessageText(row.messageId,row.messageText)}><img src="icon_edit.svg" alt="עריכה" title="עריכה" /></i>
                        )}                        
                        {row.messageIsDefault ? (
                          <i class="emptyicon"><img src="icon_default.svg" /></i>
                        ) : (
                          <i class="setdefault"><img onClick={() => setDefaultMessage(row.messageId)} src="icon_default.svg" alt="ברירת מחדל" title="ברירת מחדל" /></i>
                        )}
                        <i class="del"><img onClick={() => deleteMessageTemplate(row.messageId)} src="icon_delete.svg" alt="מחק הודעה" title="מחק הודעה" /></i>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <br />
                <div class="macros">
                  מאקרויים לשימוש: 
                  <ul>
                    <li onClick={handleMacroItemClick}>[מספר_תיבה]</li>
                    <li onClick={handleMacroItemClick}>[שם_מוסד]</li>
                    <li onClick={handleMacroItemClick}>[איש_קשר]</li>
                    <li onClick={handleMacroItemClick}>[טלפון]</li>
                    <li onClick={handleMacroItemClick}>[מידע_נוסף]</li>
                  </ul>
                </div>
                </div>
              </div>
              <Sidebar tab="messages" />         
            </div>
          </main>
        </div>
    )
 }
  
 export default Messages;
  