import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar(props) {
    const checkState = (param) => {
        return (param == props.tab)?'tagged':'';
    }
    return (
        <div class="sidebar">
        <div class="logo"><img alt="" src="/logo.svg" class="" /></div>
        <h2>ניהול מערכת</h2>
        <hr />
        <ul>
            <li className={checkState('recipients')}><Link to="/recipients"><img alt="" src="/icon_recipients.svg" /><span>תיבות</span></Link></li>
            <li className={checkState('messages')}><Link to="/messages"><img alt="" src="/icon_messages.svg" /><span>הודעות</span></Link></li>
            <li className={checkState('categories')}><Link to="/categories"><img alt="" src="/icon_messages.svg" /><span>קטגוריות</span></Link></li>
            <li className={checkState('settings')}><Link to="/settings"><img alt="" src="/icon_settings.svg" /><span>הגדרות</span></Link></li>
            <li><Link to="/logout"><span>יציאה</span></Link></li>
        </ul>
        </div>            
    )
}
  
export default Sidebar;