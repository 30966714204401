import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';

function Recipients() {  
    const ta_token = localStorage.getItem("ta_token");
    const [editingId, setEditingId] = useState(null);
    const [feedback, setFeedback] = useState([]);
    const [isFeedback, setIsFeedback] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [categories, setCategories] = useState([]);
    const [userCategories, setUserCategories] = useState([]);
    const [searchActive, setSearchActive] = useState(0);    
    const [showImport, setShowImport] = useState(0);
    const [showPrev, setShowPrev] = useState(0);
    const [showNext, setShowNext] = useState(0);
    const [categoryName, setCategoryName] = useState(0);
    const [isCategoryPage, setIsCategoryPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState([]);
    const [totalRecords, setTotalRecords] =  useState([]);
    const [importResponse, setImportState] = useState([]);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const fileInputRef = useRef(null);
    const [rcpData, setRcpData] = useState({
      rcpUniqueId: '',
      rcpId: '',
      rcpInst: '',
      rcpName: '',
      rcpMobile: '',
      rcpExtra: '',
      rcpCategoriesItems: '',
    });
    const { categoryId } = useParams();
    const navigate = useNavigate();

    async function loadCategories()
    {
        const response = await fetch(API_ENDPOINT + '/getCategories',
        {
            headers: {
              Authorization: `Bearer ${ta_token}`
            },
            method: 'GET',
        });
        const dataResponse = await response.json();
        const formattedOptions = dataResponse.categories.map((item) => ({
          value: item.categoryId,
          label: item.categoryName,
        }));
        setCategories(formattedOptions);
    }
    async function loadRecipients(page,searchText)
    {
        setCategoryName('');
        (searchText == '')?setSearchActive(0):setSearchActive(1);
        if (page<0) return
        setPage(page);
        const response = await fetch(API_ENDPOINT + '/getRecipients?categoryId=' + categoryId + '&page=' + page + '&searchText=' + searchText,
        {
            headers: {
              Authorization: `Bearer ${ta_token}`
            },
            method: 'GET',
        });
        const dataResponse = await response.json();
        if (!dataResponse.status)
        {
          navigate('/');
        } else {
          if (dataResponse.category!='')
          {
            setIsCategoryPage(1);
            setCategoryName(' - ' + dataResponse.category);
          } else {
            setIsCategoryPage(0);
            setShowImport('importCSV');
          }
          setData(dataResponse.recipients);
          setTotalRecords(dataResponse.total.toLocaleString());
          setShowPrev(dataResponse.isBack);
          setShowNext(dataResponse.isNext);
        }
    }
    useEffect(() => {
      loadRecipients(0,'');
      loadCategories();
    }, []);      
    const deleteRcp = async (rcpUniqueId) => {
      const confirmRemove = window.confirm('נא לאשר את מחיקת הרשומה');
      if (confirmRemove) {
        const formData = new FormData();
        formData.append('rcpUniqueId', rcpUniqueId);
        await fetch(API_ENDPOINT + '/deleteRecipient',
          {
              headers: {
                Authorization: `Bearer ${ta_token}`
              },
              method: 'POST',
              body: formData
          });
          const newItems = data.filter(item => item.rcpUniqueId !== rcpUniqueId);
          setData(newItems);
      }      
    }    
    const removeRecipientFromCategory = async (categoryId,rcpUniqueId) => {
      const confirmRemove = window.confirm('נא לאשר את הסרת הרשומה מהקטגוריה');
      if (confirmRemove) {
        const formData = new FormData();
        formData.append('rcpUniqueId', rcpUniqueId);
        formData.append('categoryId', categoryId);
        await fetch(API_ENDPOINT + '/removeRecipientFromCategory',
          {
              headers: {
                Authorization: `Bearer ${ta_token}`
              },
              method: 'POST',
              body: formData
          });
          const newItems = data.filter(item => item.rcpUniqueId !== rcpUniqueId);
          setData(newItems);
      }      
    }
    const createNewRecipient = async () => {
        const newItem = {
          rcpId: '',
          rcpName: '',
          rcpInst: '',
          rcpMobile: '',
          rcpExtra: '',
          rcpCategories: '',
        };
        const rcpId = newItem.rcpId;
        const rcpName = newItem.rcpName;
        const rcpInst = newItem.rcpInst;
        const rcpMobile = newItem.rcpMobile;
        const rcpExtra = newItem.rcpExtra;
        const rcpCategoriesItems = newItem.rcpCategoriesItems;
        setData([newItem,...data]);
        setEditingId( rcpId );
        setRcpData({ rcpId, rcpInst, rcpName, rcpMobile,rcpExtra,rcpCategoriesItems })
        setFeedback('');
        setIsFeedback('');
        setTimeout(function(e){
          //document.getElementById("rcpId").focus();
        },50);        
    }
    const handleInputChange = (event) => {
      setRcpData({ ...rcpData, [event.target.name]: event.target.value });
    }
    const setUserCategoriesFunc = async(res) => {
      setUserCategories(res);
    }
    const setSaveRcp = async(rcpUniqueId) => {
        const formData = new FormData();
        formData.append('rcpUniqueId', rcpUniqueId);
        formData.append('rcpId', rcpData.rcpId);
        formData.append('rcpInst', rcpData.rcpInst);
        formData.append('rcpName', rcpData.rcpName);
        formData.append('rcpMobile', rcpData.rcpMobile);      
        formData.append('rcpExtra', rcpData.rcpExtra);
        let userSelectedCategories = [];
        userCategories.forEach((element,index) => {
          if (!userSelectedCategories.includes(element.value)) {
            userSelectedCategories.push(element.value);
          }
        });
        formData.append('rcpCategories', userSelectedCategories);

        const response = await fetch(API_ENDPOINT + '/updateRecipient',
          {
              headers: {
                Authorization: `Bearer ${ta_token}`
              },
              method: 'POST',
              body: formData
          });
        const response_data = await response.json();
        if (response_data.status)
        {
          const newData = data.map(item => {
            if (item.rcpUniqueId === rcpUniqueId) {
              return { ...item, rcpId: rcpData.rcpId, rcpInst: rcpData.rcpInst, rcpName: rcpData.rcpName, rcpExtra: rcpData.rcpExtra, rcpMobile: rcpData.rcpMobile };
            }
            return item;
          });
          setData(newData);
          setEditingId(null);
          setFeedback('');
          setIsFeedback('');
          loadRecipients(0,'');
        } else {
          setFeedback('תיבה זו כבר שמורה במערכת.');
          setIsFeedback('err');
        }        
    }
    const postCSVItem = async(event) => {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      setImportState('נא להמתין...');

      const response = await fetch(API_ENDPOINT + '/importCSV',
        {
            headers: {
              Authorization: `Bearer ${ta_token}`
            },
            method: 'POST',
            body: formData,
        });
        const dataResponse = await response.json();
        if (!dataResponse.status)
        {
          setImportState(data.msg);
        } else {
          setImportState(data.msg);
          loadRecipients(0,'');
        }
        fileInputRef.current.value = '';
    }
    const importCSVItem = () => {
      fileInputRef.current.click();
    };
    const setEditRcp = (rcpUniqueId,rcpId,rcpInst,rcpName,rcpMobile,rcpExtra,rcpCategoriesItems) => {  
      setEditingId( rcpUniqueId );
      setUserCategories(rcpCategoriesItems);
      setRcpData({ rcpUniqueId, rcpId, rcpInst, rcpName, rcpMobile,rcpExtra,rcpCategoriesItems })
    }
    const previousPage = () => {
      if (searchActive)
        loadRecipients(page - 1,searchTerm);
      else
        loadRecipients(page - 1,'');      
    }
    const nextPage = () => {
      if (searchActive)
        loadRecipients(page + 1,searchTerm);
      else
        loadRecipients(page + 1,'');
    }
    const cancelSearch = () => {
      loadRecipients(0,'');
    }
    const search = () => {
      let searchText = prompt('נא להזין ערך לחיפוש');
      setSearchTerm(searchText);
      loadRecipients(0,searchText);
    }
    return (
        <div className="app adminpanel">
          <header>
          </header>
          <main>
            <div class="main">
              <div class="marea">
                <div class="contents">
                <div class="contents_head">
                  <h1>ניהול תיבות {categoryName}</h1>
                  <img alt="" src="/icon_create.svg" class="icon_create" onClick={createNewRecipient} />
                </div>    
                <div className={`feedback ${isFeedback}`}>{feedback}</div>            
                <table cellPadding="0" cellspacing="0" width="100%" border="0">
                  <thead>
                    <tr>
                      <th>מספר תיבה</th>
                      <th>שם מוסד</th>
                      <th>איש קשר</th>
                      <th>טלפון</th>
                      <th>עובדים נוספים / פרטים נוספים</th>
                      <th>קטגוריות</th>
                      <th>פעולות</th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.map((row) => (
                    <tr key={row.messageId}>
                      <td class="w-120">
                          {editingId === row.rcpUniqueId ? (
                            <input type="text" name="rcpId" id="rcpId" value={rcpData.rcpId} onChange={handleInputChange} />
                          ) : (
                            row.rcpId
                          )}
                      </td>
                      <td class="">
                          {editingId === row.rcpUniqueId ? (
                            <input type="text" name="rcpInst" value={rcpData.rcpInst} onChange={handleInputChange} />
                          ) : (
                            row.rcpInst
                          )}
                      </td>
                      <td class="">
                          {editingId === row.rcpUniqueId ? (
                            <input type="text" name="rcpName" id="rcpName" value={rcpData.rcpName} onChange={handleInputChange} />
                          ) : (
                            row.rcpName
                          )}
                      </td>
                      <td class="w-120">
                          {editingId === row.rcpUniqueId ? (
                            <input type="text" name="rcpMobile" value={rcpData.rcpMobile} onChange={handleInputChange} />
                          ) : (
                            row.rcpMobile
                          )}
                      </td>                      
                      <td>
                          {editingId === row.rcpUniqueId ? (
                            <input type="text" name="rcpExtra" value={rcpData.rcpExtra} onChange={handleInputChange} />
                          ) : (
                            row.rcpExtra
                          )}
                      </td>
                      <td>
                          {editingId === row.rcpUniqueId ? (
                             <Select
                              options={categories}
                              value={userCategories}
                              onChange={setUserCategoriesFunc}
                              inputId='rcpCategories'
                              name='rcpCategories[]'
                              isSearchable
                              isMulti
                              placeholder="קטגוריות"
                            />
                          ) : (
                            row.rcpCategories
                          )}
                          </td>
                      <td class="w-120">
                        <i className={isCategoryPage?'':'hide'}><img onClick={() => removeRecipientFromCategory(categoryId,row.rcpUniqueId)} alt="" class="icon_removecategory" src="/icon_removecategory.svg" /></i>
                        {editingId === row.rcpUniqueId ? (
                          <i onClick={() => setSaveRcp(row.rcpUniqueId)}><img alt="" src="/icon_save.svg" /></i>
                        ) : (
                          <i class="edit" onClick={() => setEditRcp(row.rcpUniqueId,row.rcpId,row.rcpInst,row.rcpName,row.rcpMobile,row.rcpExtra,row.rcpCategoriesItems)}><img alt=""  src="/icon_edit.svg" /></i>
                        )}                        
                        <i class="del"><img alt=""  onClick={() => deleteRcp(row.rcpUniqueId)} src="/icon_delete.svg" /></i>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <br />
                <span>סה"כ רשומות: {totalRecords}</span>
                <span>&nbsp;|&nbsp;</span>
                <span class={showPrev?'':'hide'}><a class="demo_file" href="#" onClick={() => previousPage()}>הקודם</a></span>
                <span class={showPrev?'':'hide'}>&nbsp;|&nbsp;</span>
                <span class={showNext?'':'hide'}><a class="demo_file" href="#" onClick={() => nextPage()}>הבא</a></span>
                <span class={showNext?'':'hide'}>&nbsp;|&nbsp;</span>
                <span><a class={searchActive?'demo_file hide':'demo_file'} href="#" onClick={() => search()}>חיפוש</a></span>
                <span><a class={!searchActive?'demo_file hide':'demo_file'} href="#" onClick={() => cancelSearch()}>בטל חיפוש</a></span>
                <span>&nbsp;|&nbsp;</span>
                <input accept=".csv" ref={fileInputRef} class="importCSVFile" type="file" onChange={postCSVItem} />
                <span class={showImport?'':'hide'}>
                  <button onClick={importCSVItem} class={showImport?'importCSV':'hide'} id="importCSV">יבוא תיבות מקובץ CSV</button>&nbsp;&nbsp;|&nbsp;&nbsp;<a class="demo_file" href="sample.csv">קובץ לדוגמא</a>
                </span>
                <div class="importResponse" dangerouslySetInnerHTML={{ __html:importResponse }}></div>
                </div>
              </div>
              <Sidebar tab="recipients" />              
            </div>
          </main>
        </div>
    )
 }
  
 export default Recipients;
  