import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isFormValid, setFormValid] = useState(true);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const navigate = useNavigate();
    const handleUsernameChange = (event) => {
      setUsername(event.target.value);
    };

    const validateForm = async() => {
      const isValid = username.trim() !== '' && password.trim() !== '';
      if (!isValid)
      {
         setFormValid('נא להזין שם משתמש וסיסמא');
      } else {
        setFormValid('');
        const formData = new FormData();
        formData.append('username', username.trim());
        formData.append('password', password.trim());

        const response = await fetch(API_ENDPOINT + '/login',
          {
              method: 'POST',
              body: formData
          });
        const response_data = await response.json();
        if (response_data.status)
        {
          localStorage.setItem("ta_token", response_data.token);
          navigate('/messages');
        } else {
          setFormValid('שם משתמש וסיסמא אינם תקינים');
        }
      }
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };

    return (
        <div className="app">
          <main>
            <div class="loginFrame logo">
              <img src="logo.svg" />
              <h1>התחבר למערכת</h1>
              <div class="loginFormFeedback">{isFormValid}</div>
              <fieldset>
                <label></label>
                <input type="text" placeholder='שם משתמש' value={username} onChange={handleUsernameChange} />
              </fieldset>
              <fieldset>
                <label></label>
                <input type="password" placeholder='סיסמא' value={password} onChange={handlePasswordChange} />
              </fieldset>
              <button onClick={validateForm}>התחברות</button>
            </div>            
          </main>
        </div>
    )
 }
  
 export default Login;
  