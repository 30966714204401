import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Login from './components/Login';
import Logout from './components/Logout';
import Recipients from './components/Recipients';
import Categories from './components/Categories';
import Messages from './components/Messages';
import Settings from './components/Settings';

function App() { 
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/recipients" element={<Recipients />} />
        <Route path="/recipients/:categoryId" element={<Recipients />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  </div>
  ); 
}

export default App;
