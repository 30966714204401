import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar';
import { Link, useNavigate } from 'react-router-dom';

function Categories(props) {  
    const ta_token = localStorage.getItem("ta_token");
    const [editingId, setEditingId] = useState(null);
    const [data, setData] = useState([]);
    const [charsCounter, setCharsCounter] = useState(0);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const inputRef = useRef(null);
    const [categoryData, setCategoryData] = useState({
      categoryId: '',
      categoryName: '',      
    });
    const navigate = useNavigate();

    async function loadCategories()
    {
      const response = await fetch(API_ENDPOINT + '/getCategories',
      {
          headers: {
            Authorization: `Bearer ${ta_token}`
          },
          method: 'GET',
      });
      const dataResponse = await response.json();
      if (!dataResponse.status)
        {
          navigate('/');
        } else {
          setData(dataResponse.categories);
        }
    }
    useEffect(() => {
        loadCategories();        
    }, []);    
    const deleteMessageTemplate = async (categoryId) => {
      const confirmRemove = window.confirm('נא לאשר את מחיקת הרשומה');
      if (confirmRemove) {
        const formData = new FormData();
        formData.append('categoryId', categoryId);
        const response = await fetch(API_ENDPOINT + '/deleteCategory',
          {
              headers: {
                Authorization: `Bearer ${ta_token}`
              },
              method: 'POST',
              body: formData
          });
          const newItems = data.filter(item => item.categoryId !== categoryId);
          setData(newItems);
      }
    }
    
    const createNewCategoryTemplate = async () => {
        const newItem = {
          categoryId: '',
          categoryName: '',
        };
        const categoryId = newItem.categoryId;
        const categoryName = newItem.categoryName;
        setData([newItem,...data]);
        setEditingId( categoryId );
        setCategoryData({ categoryId, categoryName })        
        setTimeout(function(e){
          document.getElementById("categoryName").focus();
        },50);        
    }
    const setSaveCategory = async(categoryId) => {
      const formData = new FormData();
      formData.append('categoryId', categoryId);
      formData.append('categoryName', categoryData.categoryName);
      const response = await fetch(API_ENDPOINT + '/updateCategory',
        {
            headers: {
              Authorization: `Bearer ${ta_token}`
            },
            method: 'POST',
            body: formData
        });
      const newData = data.map(item => {
        if (item.categoryId === categoryId) {
          return { ...item, categoryId: categoryData.categoryId, categoryName: categoryData.categoryName };
        }
        return item;
      });
      setData(newData);
      setEditingId(null);
    }
    const handleMacroItemClick = (event) => {
      const clickedText = event.target.textContent;      
      let categoryName = document.getElementById('categoryName').value;
      const cursorPosition = document.getElementById('categoryName').selectionStart;
      const newInputValue = categoryName.slice(0, cursorPosition) + clickedText + categoryName.slice(cursorPosition);
      document.getElementById('categoryName').setSelectionRange(cursorPosition + clickedText.length, cursorPosition + clickedText.length);
      inputRef.current.value = newInputValue;
      document.getElementById('categoryName').focus();
      //inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      //setCharsCounter(inputRef.current.value.length);
    };  
    const handleInputChange = (event) => {
      setCharsCounter(inputRef.current.value.length)
      setCategoryData({ ...categoryData, [event.target.name]: event.target.value });
    }
    const setEditCategoryName = (categoryId,categoryName) => {      
      setEditingId( categoryId );
      setCharsCounter(categoryName.length);
      setCategoryData({ categoryId, categoryName })
    }
    return (
        <div className="app adminpanel">
          <header>
          </header>
          <main>
            <div class="main">
              <div class="marea">
                <div class="contents">
                <div class="contents_head">
                  <h1>ניהול קטגוריות</h1>
                  <img src="icon_create.svg" class="icon_create" onClick={createNewCategoryTemplate} />
                </div>                
                <table cellPadding="0" cellspacing="0" width="100%" border="0">
                  <thead>
                    <tr>
                      <th>קטגוריה</th>
                      <th>סה"כ תיבות משויכות</th>
                      <th>פעולות</th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.map((row) => (
                    <tr key={row.categoryId}>
                      <td class="w-75">                          
                          {editingId === row.categoryId ? (
                            <div>
                              <input type="text" class="longInput" name="categoryName" id="categoryName" value={categoryData.categoryName} onFocus={handleInputChange} onChange={handleInputChange} ref={inputRef} />
                              &nbsp;&nbsp;&nbsp;<span>{charsCounter}</span>
                            </div>
                          ) : (
                            row.categoryName
                          )}                          
                      </td>
                      <td>{row.categoryTotal}</td>
                      <td>
                        <i class="cat"><Link to={`/recipients/${row.categoryId}`}><img src="icon_category.svg" alt="שיוך לקטגוריה" title="שיוך לקטגוריה" /></Link></i>
                        {editingId === row.categoryId ? (
                          <i onClick={() => setSaveCategory(row.categoryId)}><img src="icon_save.svg" alt="שמירה" title="שמירה" /></i>
                        ) : (
                          <i class="edit" onClick={() => setEditCategoryName(row.categoryId,row.categoryName)}><img src="icon_edit.svg" alt="עריכה" title="עריכה" /></i>
                        )}                        
                        {row.messageIsDefault ? (
                          <i class="emptyicon"><img src="icon_default.svg" /></i>
                        ) : (
                          ' '
                        )}
                        <i class="del"><img onClick={() => deleteMessageTemplate(row.categoryId)} src="icon_delete.svg" alt="מחק קטגוריה" title="מחק קטגוריה" /></i>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                </div>
              </div>
              <Sidebar tab="categories" />         
            </div>
          </main>
        </div>
    )
 }
  
 export default Categories;
  