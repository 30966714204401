import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';

function Settings() {  
    const ta_token = localStorage.getItem("ta_token");
    const [editingId, setEditingId] = useState(null);
    const [feedback, setFeedback] = useState([]);
    const [isFeedback, setIsFeedback] = useState([]);
    const [data, setData] = useState([]);
    const [importResponse, setImportState] = useState([]);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const fileInputRef = useRef(null);
    const [rcpData, setRcpData] = useState({
      settingId: '',
      settingKey: '',
      settingValue: '',
      settingDescription: '',
    });
    const navigate = useNavigate();
    
    async function loadSettings()
    {
        const response = await fetch(API_ENDPOINT + '/getSettings',
        {
            headers: {
              Authorization: `Bearer ${ta_token}`
            },
            method: 'GET',
        });
        const dataResponse = await response.json();
        if (!dataResponse.status)
        {
          navigate('/');
        } else {
          setData(dataResponse.settings);
        }
    }
    useEffect(() => {
        loadSettings();        
    }, []);   
    const handleInputChange = (event) => {
      setRcpData({ ...rcpData, [event.target.name]: event.target.value });
    }
    const setSaveRcp = async(settingId) => {
        const formData = new FormData();
        formData.append('settingId', settingId);
        formData.append('settingValue', rcpData.settingValue);

        const response = await fetch(API_ENDPOINT + '/updateSettings',
          {
              headers: {
                Authorization: `Bearer ${ta_token}`
              },
              method: 'POST',
              body: formData
          });
        const response_data = await response.json();
        if (response_data.status)
        {
          const newData = data.map(item => {
            if (item.settingId === settingId) {
              return { ...item, settingId: rcpData.settingId };
            }
            return item;
          });
          setData(newData);
          setEditingId(null);
          setFeedback('');
          setIsFeedback('');
          loadSettings();
        } else {
          setFeedback('שגיאה בשמירת ההגדרות, ערך אינו תקין.');
          setIsFeedback('err');
        }        
    }
    const setEditRcp = (settingId,settingValue) => {      
      setEditingId( settingId );
      setRcpData({ settingId,settingValue })
    }
    return (
        <div className="app adminpanel">
          <header>
          </header>
          <main>
            <div class="main">
              <div class="marea">
                <div class="contents">
                <div class="contents_head">
                  <h1>הגדרות</h1>
                </div>    
                <div className={`feedback ${isFeedback}`}>{feedback}</div>            
                <table cellPadding="0" cellspacing="0" width="100%" border="0">
                  <thead>
                    <tr>
                      <th>הגדרה</th>
                      <th>ערך</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.map((row) => (
                    <tr key={row.settingId}>
                      <td class="">
                          {editingId === row.settingId ? (
                            row.settingDescription
                          ) : (
                            row.settingDescription
                          )}
                      </td>                 
                      <td>
                          {editingId === row.settingId ? (
                            <input type="text" name="settingValue" value={rcpData.settingValue} onChange={handleInputChange} />
                          ) : (
                            row.settingValue
                          )}
                      </td>
                      <td class="w-120">
                        {editingId === row.settingId ? (
                          <i onClick={() => setSaveRcp(row.settingId)}><img alt="" src="icon_save.svg" /></i>
                        ) : (
                          <i class="edit" onClick={() => setEditRcp(row.settingId,row.settingValue)}><img alt=""  src="icon_edit.svg" /></i>
                        )} 
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                </div>
              </div>
              <Sidebar />              
            </div>
          </main>
        </div>
    )
 }
  
 export default Settings;
  