import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigate = useNavigate();
    localStorage.removeItem("ta_token");
    const redirectToHome = () => {
        setTimeout(function(e){
            navigate('/');
        },50);
    };
    redirectToHome();
 }
  
 export default Logout;
  